.WrapperHeader {
  border-top: 0;
  border-radius: 0 0 10px 10px;
  max-width: 80%;
  padding: 4px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;
  p {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    margin-right: 5px;
    height: 34px;
    min-width: 35px;
    padding-left: 1px;
    padding-bottom: 2px;
  }
}

.WrapperHeader button {
  height: 35px;
}

.wrapperAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.AmountBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.imageCoin {
  background-image: url(../../Assets/Images/coin.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 22px;
  height: 22px;
}
.mainWrapperContent {
  margin: 0px auto;
  // position: absolute;
  max-width: 90%;
  height: 100%;
}
.ScreenBlack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  // overflow-y: hidden;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 1;
}

.TokenWrapper {
  display: flex;
  flex-direction: column;
  margin: 40px 50px 50px 50px;
  background-color: #ffff;
  padding: 20px;
  border-radius: 16px;
  gap: 20px;
}

.TokenPrompt {
  margin-top: 0;
  text-align: center;
}

.wrapperTokenColumns {
  color: #000;
  p {
    margin: 5px 0;
  }
}
.wrapperTokenRow {
  display: grid;
  // grid-template-columns: repeat(6, 1fr);
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
}

.WrapperHeader p,
.TokenBlock p {
  color: #fff;
  border-radius: 50%;
  padding-top: 1px;
  padding-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #005ab3;
}

.TokenBlock {
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  white-space: nowrap;
  padding: 9px 10px 9px 14px;
  //    border-left: none;
  color: #000;
  transition: 0.1s;
  border: solid 1px#D5DDE4;
  p {
    height: 24px;
    min-width: 24px;
  }
}

.TokenBlock:hover {
  background-color: #005ab30d !important;
}

.TokenBlockText {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.simbolQuestionWrapper {
  position: absolute;
  left: -16px;
  top: -12px;
}
.simbolQuestion {
  position: fixed;
  border-radius: 100%;
  width: 18px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background: #005ab380;
  color: #fff;
  font-weight: 500;
  transition: 0.2s;
  &:hover {
    width: 22px;
    height: 22px;
    transform: translate(-2px, -2px);
  }
}
.simbolQuestionSpecial {
  border-radius: 100%;
  width: 18px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background: #005ab380;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
  transform: translateY(2px);
  margin-left: 10px;
}
.simbolQuestionArticleWrapper {
  position: absolute;
  left: 0;
  top: 0;
}
.wrapperSimbolQuestionArticle {
  display: none;
  justify-content: start;
  align-items: center;
  margin: 3px 0;
  .simbolQuestion {
    position: relative;
    cursor: pointer;
  }
  .ArticleText {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 700;
  }
}

.WrapperPopUp {
  position: fixed;
  min-width: 30%;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: #fff;
  padding: 20px;
  // max-height: 40vh;
  h1 {
    font-size: 36px;
  }
  text-align: center;
  border-radius: 10px;
}
.close {
  width: 20px;
  height: 20px;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: rotate(45deg);
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    background: #495057;
    height: 1px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 1px;
    background: #495057;
    height: 100%;
  }
}
.WrapperPopUpHeader {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.PopUpContentWrapper {
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 40px;
}
#dopActive {
  margin-top: 20px;
  padding: 10px 20px;
}
.PopUpContentWrapperBtnBlocks {
  border: 1px solid #1976d2;
  border-radius: 10px;
}
.PopUpContentText {
  border: 1px solid #1976d2;
  border-radius: 10px;
  max-width: 80%;
  margin: 7px auto;
}
.PopUpContentCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.wrapperEnd {
  height: 100vh;
  display: flex;
  text-align: center;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}
.btnCircle {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  margin: 10px;
  border: 2.5px solid #005ab3;
  font-size: 1.5rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #005ab3;
}

.disabledBtn {
  cursor: default;
  border: 2.5px solid #d5dde4;
  color: #d5dde4;
}

.btnMinus {
  padding-bottom: 10px;
}

.tokenBottomBox {
  display: flex;
  justify-content: flex-end;
}

.bottomBotBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #005ab3;
}

.tokenMapBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 50%;
}

@media screen and (max-width: 1600px) {
  .simbolQuestionWrapper {
    left: -5px;
    top: -6px;
  }
}
@media screen and (max-width: 1400px) {
  .mainWrapperContent {
    max-width: 100%;
  }

  .WrapperPopUp {
    width: 50%;
  }
}
@media screen and (max-height: 1080) {
  .TokenBlock {
    height: 20px;
  }
}
@media screen and (max-height: 800) {
  .TokenBlock {
    height: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .WrapperHeader {
    flex-direction: column-reverse;
  }

  .WrapperHeader img {
    height: 40px !important;
  }

  .TokenBlock {
    margin: 2px 5px;
    justify-content: space-between;
  }
  .WrapperPopUp {
    min-width: 40%;
  }
  .WrapperPopUpHeader {
    font-size: 1.2rem;
  }

  .TokenWrapper {
    padding: 10px 7px 10px 7px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    gap: 0;
  }

  .TokenBlock {
    padding: 5px 2px 5px 4px;
    margin: 0;
    .TokenBlockText {
      margin-right: 0;
    }
  }

  .wrapperTokenRow {
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }

  .wrapperTokenRow > div:nth-child(6n + 1) {
    border: none;
    grid-column: 1 / -1;
  }

  .tokenBottomBox {
    justify-content: center;
  }

  .tokenMapBox {
    width: 90%;
  }
}

@media screen and (max-width: 760px) {
  .WrapperHeader {
    max-width: 60%;
    p {
      margin: 0;
    }
  }

  .TokenWrapper {
    margin: 5px;
  }



  .wrapperTokenRow {
    gap: 1px;
  }

  .wrapperTokenColumns {
    gap: 5px;
  }

  .wrapperTokenColumns p {
    margin: 0;
  }

  .WrapperPopUp {
    padding: 10px;
    min-width: 60%;
  }
  .PopUpContentText {
    font-size: 0.8rem;
  }
  .WrapperPopUpHeader {
    font-size: 0.8rem;
    white-space: break-all;
  }

  .TokenBlock {
    height: 25px;
  }
  .wrapperEnd {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 450px) {
  .TokenBlock {
    margin: 2px;
    justify-content: space-between;
    height: 20px;
  }
  .WrapperHeader {
    max-width: 90%;
  }
  .WrapperPopUp {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    width: 95%;
    transform: none;
  }
  .wrapperSimbolQuestionArticle .simbolQuestion {
    margin: 0 0 0 3px;
    height: 15px;
    width: 15px;
  }

  .PopUpContentWrapper {
    margin-top: 20px;
    gap: 10px;
  }

  .btnCircle {
    min-width: 45px;
    min-height: 45px;

    max-width: 45px;
    max-height: 45px;

    border: none;
  }
  .wrapperSimbolQuestionArticle {
    font-size: 14px;
  }

  .TokenBlock {
    padding: 0 !important;
  }

  .TokenBlockText {
    font-size: 12px;
  }

  .TokenPrompt {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
  }
}

@media screen and (max-height: 800px) {
  .TokenBlock {
    margin: 2px 5px;
    justify-content: space-between;
  }
  .WrapperPopUp {
    min-width: 40%;
  }
  .WrapperPopUpHeader {
    font-size: 1.2rem;
  }

  .TokenWrapper {
    margin-top: 10px;
    padding: 10px 7px 10px 7px;
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
  }

  .TokenBlock {
    padding: 5px 2px 5px 4px;
    margin: 0;
    .TokenBlockText {
      margin-right: 0;
    }
  }
}
