.WrapperRules {
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.RulesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  // width: 100%;
  gap: 20px;
}

.RulesHeader h1 {
  margin: 0;
  font-size: 56px;
}

.headerDescription {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerDescription span {
  width: 80%;
}

.headerDescription h2 {
  margin: 0;
}

.RulesBox {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  width: 70%;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 30px;
  max-height: 100%;
  margin-bottom: 60px;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  display: flex;
  flex-direction: column;
}

.RulesBox h2 {
  text-align: center;
  margin: 10px;
}

.RulesBox p {
  margin: 5px;
  text-align: center;
}

.RuleContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: solid 1px #d5dde4;
  border-radius: 12px;
  padding: 20px;
  gap: 14px;
}

.RuleContent h2 {
  font-size: 22px;
  color: #005ab3;
  margin: 0;
}

.wrapperBottomBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 70%;
}

.mapBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
}

.stepBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepBox div {
  color: #005ab3;
}

.stepLine {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #aeb9c2;
}

.stepNumber,
.currentStep {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px #005ab3;
}

.currentStep {
  background-color: #005ab3;
  color: #fff !important;
}

@media screen and (max-width: 1280px) {
  .RulesHeader {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .RulesHeader span {
    width: 80%;
  }
  .RulesBox,
  .wrapperBottomBox {
    width: 90%;
  }
}

@media screen and (max-width: 760px) {
  h1 {
    font-size: 36px !important;
  }

  .RulesHeader span {
    width: 80%;
  }

  .WrapperRules {
    padding: 20px;
  }
  .RulesBox {
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 16px 20px 16px;
  }

  .RulesHeader h2 {
    text-align: center;
  }

  .wrapperBottomBox {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .RulesHeader span {
    width: 90%;
  }

  .wrapperBtn {
    justify-content: center !important;
    width: 100% !important;
  }

  .RulesHeader {
    margin-bottom: 10px;
  }
}

@media screen and (max-height: 800px) {
  // .RulesHeader span{width: 80%;}

  .WrapperRules {
    padding-top: 10px;
  }


  .RulesBox {
    margin-bottom: 15px;
    padding: 20px;
  }
}
