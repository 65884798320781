h1 {
  font-size: 56px;
}

.WrapperResult {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.resultHeader {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ResultBox {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 30px;
  max-height: 100%;
  margin-top: 30px;
  width: 90%;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.ResultContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 17px;
  width: 40%;
}

.ChartBox {
  display: flex;
  justify-content: center;
  width: 60%;
}

.pieChart {
  width: 580px;
}

.RadarRules {
  border: solid 1px #d5dde4;
  border-radius: 6px;
  padding: 20px;
  margin-top: 20px;
}

.ButtonBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 15px;
}

.ButtonBox div {
  border-radius: 4px;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonBox div:hover,
.AgainButton:hover {
  background-color: #80bfff;
  color: #fff;
}

.DownloadButton {
  border: solid 1px #005ab3;
  color: #005ab3;
}

.NextButton {
  background-color: #005ab3;
  color: #fff;
}

.pieChart {
  margin-left: 50px;
}

.AgainBox {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.resultMapBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  justify-content: space-around;
  gap: 10px;
}

.AgainButton {
  width: 300px;
  height: 50px;
  background-color: #fff;
  font-size: 16px;
  border: solid 1px #d5dde4;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.25s;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .pieChart {
    width: 100%;
    margin-left: 0;
  }

  .resultHeader {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ResultBox {
    width: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 16px 20px 16px;
  }

  .ResultContent,
  .ChartBox {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  h1 {
    font-size: 36px !important;
  }

  .RulesHeader {
    width: 100% !important;
  }

  .WrapperResult {
    padding: 20px;
  }

  .ButtonBox {
    flex-direction: column !important;
    margin-top: 20px;
  }

  .NextButton {
    width: auto !important;
  }

  .AgainBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .resultMapBox {
    width: 100%;
  }

  .AgainButton {
    margin: 0;
  }
}

@media screen and (max-height: 800px) {
  .ResultHeader h1 {
    margin: 10px;
  }

  .ResultBox {
    margin-top: 10px;
  }

  .AgainBox {
    margin-top: 10px;
  }

  .pieChart {
    width: 80%;
    max-width: 350px;
    margin-left: 0;
  }
}
