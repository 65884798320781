.WrapperCenterAnket {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.mobileHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
}

.mobileHeader img {
  height: 30px;
}

.mobileHeader .botBox {
  gap: 10px;
}

.WrapperAnket {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  width: 60%;
  border-radius: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
  max-height: 100%;
  margin-bottom: 60px;
}

.WrapperAnket h1 {
  text-align: center;
}

.SelectorBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.AnketLine {
  margin-top: 20px;
  font-weight: 300;
}
.titleAnket {
  font-weight: 600;
}
.screenHide {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.5);
}

.registrationBottomBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.mapBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
}

.stepBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepBox div {
  color: #005ab3;
}

.stepLine {
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: #aeb9c2;
}

.stepNumber,
.currentStep {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px #005ab3;
}

.currentStep {
  background-color: #005ab3;
  color: #fff !important;
}

.wrapperHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.bottomBotBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .WrapperCenterAnket {
    gap: 20px;
  }

  .wrapperHeader {
    gap: 10px;
  }

  .registrationBottomBox {
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .wrapperBottomBox {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .WrapperAnket {
    width: 90%;
  }

  .SelectorBox {
    flex-direction: column;
    gap: 0;
  }
}
